require('./admin.theme.config');

require('../../../node_modules/select2/dist/js/select2.full.min');
require('../../../node_modules/select2/dist/js/i18n/it');

const Italian = require('../../../node_modules/flatpickr/dist/l10n/it').default.it;

require('../../../node_modules/flatpickr/dist/flatpickr.min');
require('datatables');

window.Swal = require('sweetalert2');
window.toastr = require('jquery-toast-plugin');

window.showErrorToast = function (message) {
    $.toast({
        heading: 'Ops c\'è un errore',
        text: message,
        loader: false,
        showHideTransition: 'slide',
        position: 'bottom-right',
        bgColor: '#FD3130',
        textColor: '#ffffff'
    })
}

window.showWarningToast = function (message) {
    $.toast({
        heading: 'Attenzione!',
        text: message,
        loader: false,
        showHideTransition: 'slide',
        position: 'bottom-right',
        bgColor: '#ff6431',
        textColor: '#ffffff'
    })
}

window.showSuccessToast = function (message) {
    $.toast({
        heading: 'Azione effettuata con successo',
        text: message,
        loader: false,
        showHideTransition: 'slide',
        position: 'bottom-right',
        bgColor: '#2DDDBB',
        textColor: '#ffffff'
    })
}

window.calendarsInit = function () {

    $.HSCore.components.HSFlatpickr.init($('.js-flatpickr'), {
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d/m/Y",
        allowInput: true,
        locale: Italian,
        onChange: function (selectedDates, dateStr, instance) {
            let el = $(instance.element);
            let onChangeCallback = $(el).data('onChange');
            if (onChangeCallback) {
                window[onChangeCallback](dateStr);
            }

        },
    });
}