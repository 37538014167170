window.$ = window.jQuery = require("jquery");
require("jquery-ui");
require("../../themes/admin/dist/assets/vendor/bootstrap/dist/js/bootstrap.min");

window.axios = require('axios');

require('../../themes/admin/dist/assets/vendor/hs-toggle-password/dist/js/hs-toggle-password');
require('../../themes/admin/src/assets/vendor/hs-navbar-vertical-aside/hs-navbar-vertical-aside.js');
require('../../themes/admin/src/assets/vendor/hs-mega-menu/src/js/hs-mega-menu');
require('../../themes/admin/src/assets/js/theme-custom');
require('../../themes/admin/dist/assets/js/theme.min');
require('../../themes/admin/dist/assets/js/hs.chartjs-matrix');
require('../../themes/admin/dist/assets/vendor/hs-file-attach/dist/hs-file-attach.min');
